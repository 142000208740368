<i18n locale="th" lang="yaml" >
section.stat : "สถิติของ Ticket สำหรับ Drone รุ่นนี้"
section.progress : "Ticket ที่อยู่ระหว่างดำเนินการ"
</i18n>

<template>
	<div class="page-padding">
		<div class="page-section-title">
			{{$t('section.stat')}}
		</div>
		<div class="ticket-doughnuts">
			<TicketsActiveStatusByModelChart
				:container-width="400"
				:model-id="model.id"
				:model-name="model.name"/>

			<TicketsLotByModelChart
				:container-width="400"
				:model-id="model.id"
				:model-name="model.name"/>
		</div>

		<TicketsPerMonthByModelChart
			bordered
			class="center-block"
			:container-width="850"
			:model-id="model.id"
			:model-name="model.name"/>

		<template v-if="canViewAllTickets">
			<div class="page-section-title" style="margin-top:48px">
				{{$t('section.progress')}}
			</div>
			<TicketTableMode
				mode="progress"
				filter-name="drone_model_id"
				:filter-value="model.id"/>
		</template>
	</div>
</template>

<script>
import TicketsActiveStatusByModelChart from "@components/chart/TicketsActiveStatusByModelChart.vue"
import TicketsLotByModelChart from "@components/chart/TicketsLotByModelChart.vue"
import TicketsPerMonthByModelChart from "@components/chart/TicketsPerMonthByModelChart.vue"
import TicketTableMode from "@components/ticket/TicketTableMode.vue"
export default {
	components : {
		TicketsPerMonthByModelChart , TicketsActiveStatusByModelChart ,TicketsLotByModelChart ,
		TicketTableMode
	} ,
	props : {
		model : {
			type: Object ,
			default : () => []
		} ,
		numLots : {
			type : Number,
			default : 0
		} ,
		numDrones : {
			type : Number,
			default : 0,
		}
	} ,
	computed : {
		canViewAllTickets() {
			return this.$authorize('listAll','drone')
		}
	} ,
}
</script>
<style lang="less" scoped>
.ticket-doughnuts {
	border : 1px solid @border-color-base;
	display : flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: stretch;
	> div {
		margin : 12px 0;
	}
	margin-bottom : 32px;
}
</style>
