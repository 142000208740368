<script>
import {Doughnut} from "vue-chartjs"
export default {
	extends : Doughnut ,
	props : {
		width : {
			type : Number,
			default : undefined
		} ,
		height : {
			type : Number,
			default : undefined,
		} ,
		hideLegend : {
			type : Boolean,
			default : false,
		}
	},
	methods: {
		applyChartData(chartData=null,chartOptions={}) {
			this.renderChart(chartData,{
				responsive : true,
				legend : {position : 'bottom',display : !this.hideLegend} ,
				animation: {
					animateScale: true,
					animateRotate: true
				} ,
				...chartOptions
			})
		}
	}
}
</script>
