<i18n locale="th" lang="yaml" >
chart.name : "จำนวน Ticket แบ่งตาม Lot (ตามวันที่เกิดเหตุ ย้อนหลัง 1 ปี) "
chart.summary : "มีจำนวน Ticket ทั้งหมด {total} @:common.ticket.unit"
</i18n>

<template>
	<ChartContainer
		ref="container"
		:loading="loading"
		:is-empty="total <= 0"
		:error-message="error"
		:summary="summary"
		v-bind="$attrs">
		<LineChart ref="chart" />
		<span slot="title">
			{{$t('chart.name')}} <br/>
			<b>{{modelName}}</b>
		</span>
	</ChartContainer>
</template>

<script>
import axios from "axios"
import ChartContainer from "./general/ChartContainer.vue"
import LineChart from "./general/LineChart.vue"
import _randomColor from "randomcolor"
import ApiError from '@utils/errors/ApiError'

export default {
	components : {
		ChartContainer , LineChart
	} ,
	props : {
		modelName : {
			type : String,
			default : undefined
		} ,
		modelId : {
			type : String,
			default : undefined,
		}
	} ,
	data() {
		return {
			total : 0,
			loading : false,
			error : undefined,
		}
	} ,
	computed : {
		summary() {
			if(this.total <=0)
				return undefined;
			else
				return this.$t('chart.summary',{total : this.total})
		}
	} ,
	watch :{
		modelId() {
			this.fetchData()
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		reset() {
			this.total = 0
			this.error = null
			this.loading = false
		} ,
		convertDataset(dataset, labels) {
			if (!this.$notEmpty(dataset))
				return null
			const chartDatasets = [];

			for (const data of dataset) {
				if (data.total <= 0)
					return;

				const color = _randomColor({
					hue : 'random' ,
					luminosity : 'light',
					seed : `${data.lotId} :: ${data.lotName}`
				});
				chartDatasets.push({
					label : data.lotName,
					backgroundColor : color,
					borderColor : color,
					data : Object.values(data.months),
					fill : false,
				})
			}

			return {
				labels,
				datasets : chartDatasets
			}
		} ,
		fetchData() {
			this.reset()
			if (!this.modelId) {
				this.$refs.chart.applyChartData()
				return;
			}
			const startDate = this.$dayjs().subtract(12,'month').format("YYYY-MM-DD");
			const endDate= this.$dayjs().format("YYYY-MM-DD");

			this.loading = true
			this.error = undefined
			axios.get(`/api/tickets/stat-tickets-by-models/${this.modelId}`, {params : {startDate, endDate}}).then((response) => {
				const data = response.data.data
				const chartDataset = this.convertDataset(data.dataset, data.monthLabels)
				const chartOptions = {
					scales: {
						yAxes: [
							{
								ticks: {
									min: 0,
									precision: 0,
									suggestedMax: data.maxValue + 3,
								},
							},
						],
					},
				};
				this.total = data.total;
				this.$refs.chart.applyChartData(chartDataset, chartOptions)
			}).catch((error) => {
				this.error = ApiError.getDisplayErrorMessage(this,error)
			}).finally(() => {
				this.loading = false
			});
		}
	}
}
</script>
