<i18n locale="th" lang="yaml" >
chart.name : "จำนวน Active Ticket ตาม Lot"
chart.summary : "มี Ticket ค้างอยู่ทั้งหมด {total} @:common.ticket.unit"
</i18n>

<template>
	<ChartContainer
		:loading="loading"
		:is-empty="total <= 0"
		:error-message="error"
		:summary="summary"
		v-bind="$attrs">
		<DoughnutChart ref="chart"/>
		<span slot="title">
			{{$t('chart.name')}} <br/>
			<b>{{modelName}}</b>
		</span>
	</ChartContainer>
</template>


<script>
import axios from "axios"
import ChartContainer from "./general/ChartContainer.vue"
import DoughnutChart from "./general/DoughnutChart.vue"
import _randomColor from "randomcolor"
import ApiError from '@utils/errors/ApiError'


export default {
	components : {
		ChartContainer ,DoughnutChart
	} ,
	props : {
		modelName : {
			type : String,
			default : undefined
		} ,
		modelId : {
			type : String,
			default : undefined,
		}
	} ,
	data() {
		return {
			total : 0,
			loading : false,
			error : undefined,
		}
	} ,
	computed : {
		summary() {
			if(this.total <=0)
				return undefined;
			else
				return this.$t('chart.summary',{total : this.total})
		}
	} ,
	watch :{
		modelId() {
			this.fetchData()
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		reset() {
			this.total = 0
			this.error = null
			this.loading = false
		} ,
		convertDataset(dataset) {
			if (!this.$notEmpty(dataset))
				return null

			const counted = [];
			const labels = [];
			for(const lot in dataset) {
				if (dataset[lot] <= 0)
					continue;
				counted.push(dataset[lot]);
				labels.push(`${lot} (${dataset[lot]})`)
			}
			const colors = _randomColor({
				count : counted.length,
				luminosity : 'light'
			})

			return {
				datasets : [{
					data : counted,
					backgroundColor : colors
				}] ,
				labels
			}
		} ,
		fetchData() {
			this.reset()
			if (!this.modelId) {
				this.$refs.chart.applyChartData()
				return;
			}

			this.loading = true
			this.error = undefined
			axios.get("/api/tickets/stat-active-tickets-by-lot/"+this.modelId).then((response) => {
				const data = response.data.data
				const chartData = {}
				for (const dataset of data.dataset) {
					chartData[dataset.name] = dataset.numTickets
				}
				this.$refs.chart.applyChartData(this.convertDataset(chartData))
				this.total = data.total;
			}).catch((error) => {
				this.error = ApiError.getDisplayErrorMessage(this,error)
			}).finally(() => {
				this.loading = false
			});
		}
	}
}
</script>
